import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { Autoplay } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import item from '../../assets/fake-data/item';
import introVideo from '../../assets/intro.mp4';

import "../button/button.scss";

const SliderOne = () => {
  const [datatext] = useState({
    subtitle: 'Shiba Node',
    title: 'Become a part of the trusted network of Shibarium nodes with our SHINO.',
    // desc: 'Secure and reliable Ethereum token validation. Join the decentralized revolution with our Ethereum token. Experience the power of community-driven validation with our Ethereum token. Become a part of the trusted network of Shibarium nodes with our Ethereum token. Experience the power of community-driven validation with our Ethereum token.',
    desc: 'Ensure the integrity of your Shibarium transactions with our Shibarium node validation.',
  });

  return (
    <section id="home" className="tf-section hero-slider">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-6 col-12">
            <div className="block-text pt-24">
              <h6 className="sub-title mb-6" data-aos="fade-up">
                {datatext.subtitle}
              </h6>
              <h3 className="title mb-26" data-aos="fade-up">
                {datatext.title}
              </h3>
              <p className="desc mb-43" data-aos="fade-up">
                {datatext.desc}
              </p>
              <div className="d-flex align-items-center gap-3" data-aos="fade-up" data-aos-duration="1200">
                <a href="https://www.shibariumscan.io/token/0x213c25900f365F1BE338Df478CD82beF7Fd43F85" target="_blank" rel="noopener noreferrer" className="btn-action style-2">
                  View Chart
                </a>
                <a href="https://app.shibanodes.io" target="_blank" rel="noopener noreferrer" className="btn-action style-2">
                  STAKE SBLP
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-md-6 col-12">
            <div className="content-right d-flex justify-content-center align-items-center h-100">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "#dexscreener-embed{position:relative;width:100%;padding-bottom:125%;}@media(min-width:1400px){#dexscreener-embed{padding-bottom:65%;}}#dexscreener-embed iframe{position:absolute;width:100%;height:100%;top:0;left:0;border:0;}"
                }}
              />
              <div id="dexscreener-embed">
                <iframe src="https://dexscreener.com/shibarium/0x59bffa3e7b23bd0eacf1ba03a8865ebf1f085263?embed=1&theme=dark" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderOne;

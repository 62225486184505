const menus = [
  {
    id: 1,
    name: 'Home',
    links: '#body',
  },
  {
    id: 2,
    name: 'About',
    links: '#about',
  },
  {
    id: 3,
    name: 'Tokenomics',
    links: '#tokenomics',
  },
  {
    id: 4,
    name: 'Roadmap',
    links: '#roadmap',
  },
];

export default menus;

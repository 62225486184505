import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

const Action = () => {
  const [data] = useState({
    title: 'Become a part of',
    desc: 'the trusted network of Shibarium nodes with our Ethereum token',
  });

  return (
    <section className="tf-section action">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="action__body" data-aos="fade-up">
              <div className="block-text">
                <h3 className="mb-13">{data.title}</h3>
                <p className="fs-21 mb-7">{data.desc}</p>
              </div>
              <a href="https://twitter.com/ShibaNodesERC" target="_blank" rel="noopener noreferrer" className="btn-action style-2">
                Follow us on Twitter
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Action;

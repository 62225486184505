import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = (props) => {
  const data = props.data;

  return (
    <section id="tokenomics" className="tf-section porfolio">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="block-text center">
              <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000">
                <span>
                  Token
                  <br />
                  omics
                </span>
              </h1>
              <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">
                Tokenomics
              </h5>
              <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">
                Join the decentralized revolution <br />
                with our Ethereum token
              </h3>
            </div>
          </div>
        </div>
        <div style={{ justifyContent: 'stretch' }} className="container row mt-61">
          {data.map((data, index) => (
            <div key={index} className="col-md-6 mb-4">
              <div className="h-100 icon-box bg-2" data-aos="fade-up">
                {/* <div className="icon">
                  <img src={data.img} alt="Monteno" />
                </div> */}
                <div className="content">
                  {data?.step && <p className="fs-16 color-main">{data.step}</p>}
                  <Link to="#" className="h5">
                    {data.title}
                  </Link>
                  <p className="fs-18">{data.desc} </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;

const dataPortfolio = [
  {
    step: 'Step 01',
    title: 'Initial coin offering (ICO)',
    desc: '95% of the supply will be added to Uniswap',
  },
  {
    step: 'Step 02',
    title: 'Token distribution',
    desc: 'The remaining tokens (5%) will be distributed among the project team, early investors, and locked as a reserve for future use',
  },
  {
    step: 'Step 03',
    title: 'Initial buy and sell tax',
    desc: 'A 5% tax will be applied to all initial token purchases and sales. This tax will be used to fund the buyback and burn mechanism',
  },
  {
    step: 'Step 04',
    title: 'Token burn',
    desc: 'A token burn mechanism will be implemented where a 1% of tokens used for transactions will be permanently removed from circulation, reducing the total supply over time',
  },
  {
    step: 'Step 05',
    title: 'Token buyback',
    desc: 'The project will implement a token buyback program where the project will buyback tokens from the market using the funds generated from the initial buy and sell tax. The bought back tokens will then be burned, reducing the total supply of tokens in circulation. The buyback program will be conducted weekly to ensure a stable token price',
  },
  {
    step: 'Step 06',
    title: '1 Billion Token supply cap',
    desc: 'The total supply of SHINO will be capped at 1 billion tokens, and no more tokens will be created after the ICO',
  },
];

export default dataPortfolio;

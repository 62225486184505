const dataRoadMap = [
  // {
  //   time: "February 01, 2022",
  //   title: "Idea Generation",
  //   desc: "Oficia dese runt mollit anim id est labo met conse ctetur adipi smod tempor inc ididunt ut labore et dolore quis",
  //   class: "left",
  // },
  // {
  //   time: "March 30, 2022",
  //   title: "Design & Development",
  //   desc: "Oficia dese runt mollit anim id est labo met conse ctetur adipi smod tempor inc ididunt ut labore et dolore quis",
  //   class: "right mt-223",
  // },
  // {
  //   time: "April 20, 2022",
  //   title: "Initial Release",
  //   desc: "Oficia dese runt mollit anim id est labo met conse ctetur adipi smod tempor inc ididunt ut labore et dolore quis",
  //   class: "left mt--23",
  // },
  // {
  //   time: "May 30, 2022",
  //   title: "Result & Final Report",
  //   desc: "Oficia dese runt mollit anim id est labo met conse ctetur adipi smod tempor inc ididunt ut labore et dolore quis",
  //   class: "right mt-200",
  // },
  {
    title: 'Development and testing of the Ethereum token',
    desc: 'This will involve creating the token on the Ethereum blockchain, testing its functionality, and ensuring that it meets all the necessary technical requirements to become a Shibarium validator.',
  },
  {
    title: 'Community engagement and building',
    desc: `This will involve reaching out to the Ethereum and crypto communities, building a strong and dedicated following, and gaining support for the token's bid to become a Shibarium validator.`,
  },
  {
    title: 'Application to the Shibarium network',
    desc: 'Once the token has been fully developed and tested, and a strong community has been built, the team will apply to the Shibarium network for validation status.',
  },
  {
    title: 'Auditing and security review',
    desc: 'Once the application has been submitted, the token will be put through a thorough audit and security review to ensure that it meets all the necessary security and reliability standards required to be a Shibarium validator.',
  },
  {
    title: 'Onboarding and integration',
    desc: 'If the token is successful in its bid to become a Shibarium validator, it will be integrated into the network and onboarded as a validator node.',
  },
  {
    title: 'Continuous monitoring and maintenance',
    desc: 'Once the token is live as a Shibarium validator, it will be continuously monitored and maintained to ensure that it continues to meet the necessary security and reliability standards and to keep the token updated with the latest developments in the Ethereum and crypto space.',
  },
  {
    title: 'Marketing, awareness and education',
    desc: 'The team will be creating awareness and educating the community on the benefits of having a token as a Shibarium validator. Also they will be marketing the token to potential investors and users of the token to increase adoption.',
  },
];

export default dataRoadMap;
